
body {
  margin: 0;
  background-color: #050510; /* GitHub dark background */
  color: #eeeef3; /* GitHub light text */
 
}


html {
  scroll-behavior: smooth;
}

.bg-nav {
  background-color: #050510; /* GitHub nav background */
  z-index: 1000;
}
.text-primary {
  color: #6d42ff; /* GitHub blue accent */
}
.text-secondary {
  color: #fff; /* GitHub secondary text */
}
.bg-primary {
  background-color: #050510; /* GitHub green accent */
}
.bg-secondary {
  background-color: #0f0f18; /* GitHub secondary background */
}
.bg-button {
  background-color: #e13a67; /* GitHub button background */
}
.bg-button:hover {
  background-color: #2c1979; /* GitHub button hover */
}




.bg-card-section{
  background-color: #0f0f18;
  border: 1px solid #1e1e22;
}


.bg-img{
  width: 100%;
  height: 100%;
  background-image: url('/src/assets/someof.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
}

#sidebar{
  z-index: 100;
  padding-top: 20%;
  width: 200px;
  background-color: #131335;
}

.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.scroll-animation.show {
  opacity: 1;
  transform: translateY(0);
}

.sidebar {
  display: none;
}

.sidebar.show {
  display: block;
}

/* Add this CSS to your stylesheet */
@keyframes slowFloat {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.scroll-animation img {
  animation: slowFloat 3s ease-in-out infinite;
}

.scroll-to-top {
  margin-left: 5px;
  position: sticky;
  bottom: 20px;
  right: 20px;
  background-color: #d11f57;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: #991740;
}

.iconi{
  margin-left: 5px;
}