@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #050510; /* GitHub dark background */
  color: #eeeef3; /* GitHub light text */
  font-family: 'Inter', sans-serif;
  background-color: saturate(#ff0000, 50%);
  filter: saturate(120%);
  
}

* {
  font-family: 'Inter', sans-serif !important;
    
}

html {
  scroll-behavior: smooth;
}

.bg-nav {
  background-color: #050510; /* GitHub nav background */
  z-index: 1000;
}
.text-primary {
  color: #f5678d; /* GitHub blue accent */
}
.text-secondary {
  color: #fff; /* GitHub secondary text */
}
.bg-primary {
  background-color: #050510; /* GitHub green accent */
}
.bg-secondary {
  background-color: #0f0f18; /* GitHub secondary background */
}
.bg-button {
  background-color: #f5678d; /* GitHub button background */
}
.bg-button:hover {
  background-color: #2c1979; /* GitHub button hover */
}




.bg-card-section{
  background-color: #0f0f18;
  border: 1px solid #1e1e22;
}


.bg-img{
  width: 100%;
  height: 100%;
  background-image: url('/src/assets/someof.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
}

#sidebar{
  z-index: 100;
  padding-top: 20%;
  width: 200px;
  background-color: #131335;
}

.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.scroll-animation.show {
  opacity: 1;
  transform: translateY(0);
}